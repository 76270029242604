import React, { useEffect, useCallback } from "react";
import cn from "classnames";
import { Container } from "react-awesome-styled-grid";
import { graphql, useStaticQuery } from "gatsby";
import { isBrowser } from "@superlogica/design-ui";
import { useWindowScroll } from "react-use";

import { useConfig, useHeader } from "hooks";
import Breadcrumb from "../Breadcrumb";
import { headerConfig } from "config";

import * as S from "./SubBar.styled";

export type SubBarItemProps = Pick<UrlProps, "href" | "label"> & {
  platform: Platform;
};

interface SubBarDataProps {
  allSubmenuJson: {
    nodes: SubBarItemProps[];
  };
}

const SubBar: React.FC = () => {
  const { config } = useConfig();
  const { y } = useWindowScroll();

  const {
    headerState: {
      affixedSubBar,
      openedMenu,
      expandedSubMenu,
      notificationHeight,
    },
    setHeaderState,
  } = useHeader();

  const affixHeader = useCallback(() => {
    if (y > headerConfig.NAVBAR_HEIGHT + notificationHeight) {
      !affixedSubBar && setHeaderState({ affixedSubBar: true });
    } else if (y <= headerConfig.NAVBAR_HEIGHT + notificationHeight) {
      affixedSubBar && setHeaderState({ affixedSubBar: false });
    }
  }, [y, affixedSubBar]);

  useEffect(() => {
    !openedMenu && affixHeader();
  }, [y]);

  const isHome = isBrowser ? window.location.pathname === "/" : false;

  if (!config.crumbs) {
    return null;
  }

  return (
    <S.Box className={cn({ "is-home": isHome })}>
      <S.Wrapper className={cn({ "is-affixed": affixedSubBar })}>
        <Container>
          <S.Content>
            {!!config.crumbs && (
              <Breadcrumb
                items={config.crumbs}
                hidden={["/condominios/servicos-financeiros/"]}
              />
            )}
          </S.Content>
        </Container>
      </S.Wrapper>
    </S.Box>
  );
};

export default SubBar;
