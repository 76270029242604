/* eslint-disable prettier/prettier */
import ArrowDown from './arrow-down.svg'
import ArrowLeft2 from './arrow-left-2.svg'
import ArrowLeft from './arrow-left.svg'
import ArrowRight2 from './arrow-right-2.svg'
import ArrowRight from './arrow-right.svg'
import ArrowUp from './arrow-up.svg'
import BadgeAbcd from './badge-abcd.svg'
import BrandColor from './brand-color.svg'
import BrandWhatsapp from './brand-whatsapp.svg'
import Brand from './brand.svg'
import Check1 from './check-1.svg'
import Check from './check.svg'
import Close from './close.svg'
import Element1 from './element-1.svg'
import ElementHorizontal from './element-horizontal.svg'
import Element from './element.svg'
import SingleNeutralActions from './single-neutral-actions.svg'
import SocialFacebook from './social-facebook.svg'
import SocialInstagram from './social-instagram.svg'
import SocialLinkedin from './social-linkedin.svg'
import SocialSpotify from './social-spotify.svg'
import SocialTwitter from './social-twitter.svg'
import SocialYoutube from './social-youtube.svg'

const vectors = {
  'arrow-down': ArrowDown,
  'arrow-left-2': ArrowLeft2,
  'arrow-left': ArrowLeft,
  'arrow-right-2': ArrowRight2,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  'badge-abcd': BadgeAbcd,
  'brand-color': BrandColor,
  'brand-whatsapp': BrandWhatsapp,
  'brand': Brand,
  'check-1': Check1,
  'check': Check,
  'close': Close,
  'element-1': Element1,
  'element-horizontal': ElementHorizontal,
  'element': Element,
  'single-neutral-actions': SingleNeutralActions,
  'social-facebook': SocialFacebook,
  'social-instagram': SocialInstagram,
  'social-linkedin': SocialLinkedin,
  'social-spotify': SocialSpotify,
  'social-twitter': SocialTwitter,
  'social-youtube': SocialYoutube,
}

export type VectorsProps = keyof typeof vectors

export default vectors