import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { rem, rgba } from 'polished'
import { Chevron, media, parseColor, parseSize } from '@superlogica/design-ui'
import { headerConfig } from 'config'
import { Link } from 'components/helpers'

const menuMobile = 'sm'
const menuDesktop = 'md'

export const Toggle = styled.div`
  display: none;
  margin-left: auto;

  ${media.lessThan(menuMobile)} {
    display: block;
  }
`

export const SubNavLink = styled(Link)`
  font-size: ${rem(14)};
  font-weight: 600;

  ${({ theme }) => `
    color: ${parseColor(theme.colors.babar500)};

    &:hover,
    &.active {
      color: ${parseColor(theme.colors.sonic500)};
    }
  `}

  ${media.lessThan(menuMobile)} {
    display: block;
    padding-bottom: ${parseSize(16)};
  }
`

export const NavLink = styled(Link)`
  position: relative;
  display: block;
  padding: 24px;
  font-size: ${rem(14)};
  font-weight: 600;

  ${media.lessThan(menuMobile)} {
    display: flex;
    padding: 16px 0;
  }
`

export const Item = styled.li``

export const List = styled.ul`
  &::-webkit-scrollbar {
    display: none;
  }
`

export const ChevronIcon = styled(Chevron)`
  ${media.greaterThan(menuDesktop)} {
    display: none;
  }
`

export const SubMenuTitle = styled.p`
  font-weight: 700;
  font-size: ${rem(14)};
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;

  ${({ theme }) => `
    color: ${parseColor(theme.colors.babar900)};
  `}

  ${media.lessThan(menuMobile)} {
    display: none;
  }
`

export const SocialWrapper = styled.div`
  margin-top: 24px;
  padding-top: 24px;

  ${({ theme }) => `
    border-top: 1px solid ${parseColor(theme.colors.babar75)};
  `}

  ${media.lessThan(menuMobile)} {
    display: none;
  }
`

export const Banners = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  width: 100%;
  height: 340px;
  column-gap: 32px;

  > div {
    width: 100%;
  }

  ${media.lessThan(menuMobile)} {
    display: none;
  }
`

export const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 280px;
  max-width: 280px;
  margin-right: 32px;

  ${List} {
    margin-bottom: auto;

    ${Item} {
      margin-bottom: 8px;
    }
  }

  ${media.lessThan(menuMobile)} {
    flex: 1;
    max-width: 100%;

    ${List} {
      ${Item} {
        margin-right: 0;
      }
    }
  }
`

export const SubMenuContent = styled.div`
  display: flex;
  width: 100%;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`

export const SubMenuWrapper = styled(motion.div)`
  width: 100%;
  overflow: hidden;

  ${({ theme: { colors } }) => `
    ${media.greaterThan(menuDesktop)} {
      position: absolute;
      left: 0;
      top: calc(100% - 1px);
      padding: 32px 0;
      background-color: ${parseColor(colors.babar0)};
      box-shadow: 0px 32px 32px ${rgba(parseColor(colors.babar1000), 0.07)};
      border-top: 1px solid ${parseColor(colors.babar75)};
    }
  `}

  ${media.lessThan(menuMobile)} {
    > div {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
`

export const Menu = styled.nav`
  flex: 1 0 auto;

  ${({ theme }) => css`
    > ${List} {
      display: flex;
      align-items: center;

      > ${Item} {
        &:last-of-type {
          margin-left: auto;
          margin-right: 0;

          ${NavLink} {
            padding-left: 0;
            padding-right: 0;
          }
        }

        ${NavLink} {
          color: ${parseColor(theme.colors.babar600)};
        }

        ${media.greaterThan(menuDesktop)} {
          &:not(:last-of-type) {
            ${NavLink} {
              &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 1px;
                margin: 0 auto;
                display: block;
                width: calc(100% - 48px);
                height: 2px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                background-color: transparent;
              }
            }
          }

          &.is-expanded {
            ${NavLink} {
              color: ${parseColor(theme.colors.babar1000)};

              &:after {
                background-color: ${parseColor(theme.colors.sonic500)};
              }
            }
          }
        }
      }
    }

    ${media.lessThan(menuMobile)} {
      position: absolute;
      z-index: 2000;
      top: 100%;
      left: 0;
      background-color: ${parseColor(theme.colors.babar0)};
      transform: translateX(-100%);
      transition: all 300ms ease-in-out;
      width: 100%;
      height: calc(
        100vh - ${parseSize(headerConfig.NAVBAR_HEIGHT)} -
          var(--notification-height)
      );

      &.is-opened {
        transform: translateX(0%);
      }

      > ${List} {
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0 32px;
        overflow-y: auto;
        -ms-overflow-style: none;

        > ${Item} {
          width: 100%;

          &.is-expanded {
            ${NavLink} {
              color: ${parseColor(theme.colors.babar1000)};
            }
          }

          &:not(:last-of-type) {
            border-bottom: 1px solid ${parseColor(theme.colors.babar75)};
          }

          &:last-of-type {
            margin-top: auto;
            border-top: 1px solid ${parseColor(theme.colors.babar75)};
          }
        }
      }
    }
  `}
`

export const Brand = styled.div`
  flex: 0 0 124px;
  margin-right: 60px;

  ${media.lessThan(menuDesktop)} {
    margin-right: 30px;
  }
`

export const Bar = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  height: ${parseSize(headerConfig.NAVBAR_HEIGHT)};
`

export const Wrapper = styled.header`
  position: relative;
  z-index: 2000;
  width: 100%;
  height: ${parseSize(headerConfig.NAVBAR_HEIGHT)};

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.babar0)};

    &:not(.is-home) {
      &:after {
        background-color: ${parseColor(colors.babar75)};
      }
    }

    ${media.lessThan(menuMobile)} {
      &:after {
        background-color: ${parseColor(colors.babar75)};
      }
    }
  `}
`
