import React, { useCallback, useEffect } from 'react'
import cn from 'classnames'
import { Container } from 'react-awesome-styled-grid'
import { Hamburguer, Vector } from '@superlogica/design-ui'
import { useLockBodyScroll, useWindowSize } from 'react-use'

import { Link } from 'components/helpers'
import { useHeader } from 'hooks'
import { HeaderMenuProps } from './Header'
import HeaderItem from './Header.Item'

import * as S from './Header.styled'

interface NavProps {
  items?: HeaderMenuProps[]
}

const Nav: React.FC<NavProps> = ({ items = [] }) => {
  const {
    headerState: { openedMenu, expandedMenu },
    setHeaderState
  } = useHeader()

  const { width } = useWindowSize()

  useLockBodyScroll(openedMenu)

  useEffect(() => {
    const isDesktopAndOpenedMenu = width >= 993 && openedMenu

    if (isDesktopAndOpenedMenu) {
      setHeaderState({ openedMenu: false })
    }
  }, [width, openedMenu])

  const isExpanded = useCallback(
    (id?: string) => expandedMenu === id,
    [expandedMenu]
  )

  const toggle = useCallback(
    (id?: string) => {
      setHeaderState({ expandedMenu: isExpanded(id) ? false : id })
    },
    [expandedMenu]
  )

  return (
    <Container>
      <S.Bar>
        <S.Brand>
          <Link to="/" title="Superlógica">
            <Vector name="brand-color" width={124} height={19} />
          </Link>
        </S.Brand>
        {/* <S.Menu className={cn({ 'is-opened': openedMenu })}>
          <S.List>
            {items.map(({ label, ...restItem }) => (
              <HeaderItem
                key={`item-${label}`}
                label={label}
                isExpanded={isExpanded}
                toggle={toggle}
                {...restItem}
              />
            ))}
          </S.List>
        </S.Menu>
        <S.Toggle>
          <Hamburguer
            color="babar500"
            active={openedMenu}
            onClick={() => setHeaderState({ openedMenu: !openedMenu })}
          />
        </S.Toggle> */}
      </S.Bar>
    </Container>
  )
}

export default Nav
